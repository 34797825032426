import React, { useState, useEffect, useContext } from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import {
  useAuthorization,
  useSelectedBot
} from '../../../uni-comms-api/hooks/authentication';
import { Clawson } from '../../clawson';
import {
  AIIcon,
  DashBoardMenuIcon,
  LiveChatMenuIcon,
  MyAssistantMenuIcon,
  ConversationReviewMenuIcon,
  ConversationViewMenuIcon,
  ChannelMenuIcon,
  ClawsonSupportMenuIcon
} from './menuIcons';
import LaunchAI from '../LaunchAI';
import { ModalProvider } from './../ModalManager';

const patternFlows = /^\/flows\/\w+/;
const patternRequestViewer = /^\/my-ai\/request-viewer\/\w*$/;

export default function HeaderMenu() {
  const location = useLocation();
  const { selectedBot, isSelfServe } = useSelectedBot();
  const { open, setOpen } = useContext(Clawson);

  const [current, setCurrent] = useState(location.pathname);

  const hasFlows = selectedBot.signalProvider;
  const isMatchingContentMenu = patternFlows.test(location.pathname);
  const isMatchingRequestViewerMenu = patternRequestViewer.test(
    location.pathname
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const handleClick = e => {
    setCurrent(e.key);
  };

  const onToggleClawsonSupport = () => {
    setOpen(isOpen => !isOpen);
  };

  return (
    <>
      <ReefMenu
        mode="horizontal"
        theme="dark"
        onClick={handleClick}
        selectedKeys={[current]}
      >
        {useAuthorization('dashboard:read') && (
          <MenuLink
            path="/dashboard"
            icon={<DashBoardMenuIcon />}
            current={current}
          >
            Dashboard
          </MenuLink>
        )}
        {useAuthorization('bots/responseVersions:read') && (
          <MenuLink
            key={'content'}
            path={
              hasFlows
                ? isMatchingContentMenu
                  ? location.pathname
                  : '/flows'
                : '/my-ai/responses'
            }
            icon={<MyAssistantMenuIcon />}
            current={current}
          >
            {hasFlows ? 'Content' : 'Responses'}
          </MenuLink>
        )}
        {useAuthorization('bots/responseVersions:read') && (
          <MenuLink
            path="/my-ai/assistant-settings"
            icon={<ChannelMenuIcon />}
            current={current}
          >
            Style
          </MenuLink>
        )}

        {isSelfServe && (
          <MenuLink
            path="/my-ai/ai-settings"
            icon={<AIIcon />}
            current={current}
          >
            AI settings
          </MenuLink>
        )}

        <SeparatorItem key="separator" />
        {useAuthorization('conversationReview:read') && hasFlows && (
          <MenuLink
            path="/my-ai/conversation-review"
            icon={<ConversationReviewMenuIcon />}
            current={current}
          >
            Conversation review
          </MenuLink>
        )}
        {hasFlows && (
          <MenuLink
            path={
              isMatchingRequestViewerMenu
                ? location.pathname
                : '/my-ai/request-viewer'
            }
            icon={<ConversationViewMenuIcon />}
            current={current}
            alt="conversationReview"
          >
            Request viewer
          </MenuLink>
        )}
        {useAuthorization('liveChat:read') && (
          <MenuLink
            path="/live-chat"
            icon={<LiveChatMenuIcon />}
            current={current}
          >
            Live chat
          </MenuLink>
        )}
        <MenuItem onClick={onToggleClawsonSupport}>
          <ClawsonSupportMenuIcon open={open} />
        </MenuItem>
        <MenuItem>
          <ModalProvider>
            <LaunchAI label="Launch your AI" />
          </ModalProvider>
        </MenuItem>
      </ReefMenu>
    </>
  );
}

function MenuLink({ path, icon, children, current }) {
  return (
    <Menu.Item
      key={path}
      icon={icon}
      className={path === current ? 'active-menu-item' : ''}
    >
      <Link to={path}>{children}</Link>
    </Menu.Item>
  );
}

const MenuItem = styled(Menu.Item)`
  padding: 0px 10px !important;
`;

const ReefMenu = styled(Menu)`
  flex-grow: 1;
  padding-right: 20px;

  .ant-menu-overflow-item {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
    .ant-menu-title-content {
      line-height: 1;
    }
    .ant-dropdown-trigger {
      display: flex;
    }
  }

  &.ant-menu.ant-menu-dark {
    .ant-menu-overflow-item {
      svg {
        fill: #fff;
        stroke: #fff;
        transition: stroke 0.3s;
      }
      .ant-menu-title-content {
        a,
        .ant-dropdown-trigger {
          color: #fff;
        }
      }

      &.active-menu-item,
      &.ant-menu-item:hover {
        background-color: inherit;
        svg path,
        svg line {
          fill: #ea555f;
          stroke: #ea555f;
        }
        .ant-menu-title-content {
          a {
            color: #ea555f;
          }
        }
      }
    }
  }
`;

const SeparatorItem = styled(Menu.Item)`
  margin-right: auto;
`;
